import React, { useEffect, useState } from "react";
import Slider from "react-slick";
import styles from "./ExploreProduct.module.css";
import client, { urlFor } from "../../sanityClient";
import { useNavigate } from "react-router-dom";

const ExploreProduct = ({ currentProduct }) => {
  const [products, setProducts] = useState([]);
  const [pageTitle, setPageTitle] = useState("");
  const [pageSubtitle, setPageSubtitle] = useState("");
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
  const navigate = useNavigate();

  useEffect(() => {
    const handleResize = () => setIsMobile(window.innerWidth <= 768);

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    client
      .fetch(
        `*[_type == "productPageContent"]{
          title,
          subtitle,
          products[] {
            metalName,
            title,
            description,
            imgSrc {
              asset -> {
                _id
              }
            }
          }
        }`
      )
      .then((data) => {
        if (data.length > 0) {
          const pageContent = data[0];
          setPageTitle(pageContent.title || "");
          setPageSubtitle(pageContent.subtitle || "");

          const filteredProducts = pageContent.products
            .filter((product) => product.metalName !== currentProduct)
            .slice(0, 4);

          setProducts(filteredProducts || []);
        }
      })
      .catch((error) => {
        console.error("Error fetching product data:", error);
      });
  }, [currentProduct]);

  const handleProductClick = (metalName) => {
    navigate(`/product-detail/${metalName}`);
  };

  const settings = {
    dots: true,
    infinite: true,
    arrows: false,
    speed: 500,
    slidesToShow: isMobile ? 2 : 1, // Show 2 slides at a time on mobile
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 5000,
  };

  const ProductCard = ({ image, title, description, metalName }) => (
    <div className={styles.card} onClick={() => handleProductClick(metalName)}>
      <img src={image} alt={title} className={styles.image} />
      <h3 className={styles.title}>{title}</h3>
      <p className={styles.description}>{description}</p>
      <a
        className={styles.readMore}
        onClick={() => handleProductClick(metalName)}
      >
        Read more
      </a>
    </div>
  );

  return (
    <div className={styles.exploreProduct}>
      <h1 className={styles.heading}>{"Explore More Products"}</h1>
      <p className={styles.subheading}>
        {
          "Achieve unparalleled surface finishes with our industry-leading steel cut wire shots."
        }
      </p>
      {isMobile ? (
        <Slider {...settings} className={styles.sliderContainer}>
          {products.map((product) => (
            <ProductCard
              key={product.metalName}
              image={urlFor(product.imgSrc.asset).url()}
              title={product.title}
              description={product.description}
              metalName={product.metalName}
            />
          ))}
        </Slider>
      ) : (
        <div className={styles.cardsContainer}>
          {products.map((product) => (
            <ProductCard
              key={product.metalName}
              image={urlFor(product.imgSrc.asset).url()}
              title={product.title}
              description={product.description}
              metalName={product.metalName}
            />
          ))}
        </div>
      )}
    </div>
  );
};

export default ExploreProduct;
