// // WireShotCards.js
// import React from "react";
// import styles from "./WireShotCards.module.css";

// const WireShotCards = () => {
//   const cardData = [
//     {
//       title: "Customer Base",
//       description:
//         "We are committed to sustainable practices, ensuring our manufacturing processes minimize environmental impact. Our eco-friendly approach also supports your sustainability goals.",
//     },
//     {
//       title: "Customer Base",
//       description:
//         "We are committed to sustainable practices, ensuring our manufacturing processes minimize environmental impact. Our eco-friendly approach also supports your sustainability goals.",
//     },
//     {
//       title: "Customer Base",
//       description:
//         "We are committed to sustainable practices, ensuring our manufacturing processes minimize environmental impact. Our eco-friendly approach also supports your sustainability goals.",
//     },
//     {
//       title: "Customer Base",
//       description:
//         "We are committed to sustainable practices, ensuring our manufacturing processes minimize environmental impact. Our eco-friendly approach also supports your sustainability goals.",
//     },
//     {
//       title: "Customer Base",
//       description:
//         "We are committed to sustainable practices, ensuring our manufacturing processes minimize environmental impact. Our eco-friendly approach also supports your sustainability goals.",
//     },
//     {
//       title: "Customer Base",
//       description:
//         "We are committed to sustainable practices, ensuring our manufacturing processes minimize environmental impact. Our eco-friendly approach also supports your sustainability goals.",
//     },
//   ];

//   return (
//     <div className={styles.cardsContainer}>
//       {cardData.map((card, index) => (
//         <div className={styles.card} key={index}>
//           <h3 className={styles.cardTitle}>{card.title}</h3>
//           <p className={styles.cardDescription}>{card.description}</p>
//         </div>
//       ))}
//     </div>
//   );
// };

// export default WireShotCards;

import React, { useEffect, useState } from "react";
import styles from "./WireShotCards.module.css";
import client from "../../sanityClient";

const WireShotCards = () => {
  const [cardData, setCardData] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      const query = '*[_type == "wireShotCards"]';
      const data = await client.fetch(query);
      setCardData(data);
    };

    fetchData();
  }, []);

  return (
    <div className={styles.cardsContainer}>
      {cardData.map((card, index) => (
        <div className={styles.card} key={index}>
          <h3 className={styles.cardTitle}>{card.title}</h3>
          <p className={styles.cardDescription}>{card.description}</p>
        </div>
      ))}
    </div>
  );
};

export default WireShotCards;
