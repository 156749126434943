import React from "react";
import IndustryHero from "./IndustryHero/IndustryHero";
import IndustryServedList from "./IndustryServedList/IndustryServedList";
import PopularBlog from "../BlogPage/PopularBlog/PopularBlog";

const IndustriesServed = () => {
  return (
    <div>
      <IndustryHero />
      <IndustryServedList />
      <PopularBlog />
    </div>
  );
};

export default IndustriesServed;
