import React from "react";
import PopularBlog from "../BlogPage/PopularBlog/PopularBlog";
import BlogDetail from "./BlogDetail";

const BlogDetailPage = () => {
  return (
    <div>
      <BlogDetail />
      <PopularBlog />
    </div>
  );
};

export default BlogDetailPage;
