// import React from "react";
// import styles from "./IndustryHero.module.css";
// import industryImage from "../image 4.png"; // Replace with the actual path to your image

// const IndustryHero = () => {
//   return (
//     <section className={styles.heroContainer}>
//       <h1 className={styles.heroTitle}>Industries We Serve</h1>
//       <p className={styles.heroSubtitle}>
//         Discover how our high-quality cut wire shots provide superior
//         performance and unmatched benefits across a variety of industries.
//       </p>
//       <img
//         src={industryImage}
//         alt="Industries We Serve"
//         className={styles.heroImage}
//       />
//     </section>
//   );
// };

// export default IndustryHero;

import React, { useEffect, useState } from "react";
import styles from "./IndustryHero.module.css";
import client, { urlFor } from "../../sanityClient";

const IndustryHero = () => {
  const [heroData, setHeroData] = useState(null);

  useEffect(() => {
    const fetchHeroData = async () => {
      const query = '*[_type == "industryHero"][0]'; // Fetch the first document of type 'industryHero'
      const data = await client.fetch(query);
      setHeroData(data);
    };

    fetchHeroData();
  }, []);

  if (!heroData) {
    return <div></div>;
  }

  return (
    <div className={styles.wrapper}>
      <section className={styles.heroContainer}>
        <h1 className={styles.heroTitle}>{heroData.title}</h1>
        <p className={styles.heroSubtitle}>{heroData.subtitle}</p>
        <img
          src={urlFor(heroData.image).url()} // Use urlFor to get the image URL
          alt={heroData.title}
          className={styles.heroImage}
        />
      </section>
    </div>
  );
};

export default IndustryHero;
