import React, { useState } from "react";
import { v4 as uuidv4 } from "uuid"; // Import uuid
import styles from "./ContactForm.module.css";
import ContactInfoCard from "./contactInfoCard/ContactInfoCard";

const ContactForm = () => {
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    company: "",
    phone: "",
    message: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const submissionId = uuidv4(); // Generate a unique ID

    // Add form submission logic here
    console.log("Form submitted:", {
      id: submissionId,
      ...formData,
    });

    // Optionally reset the form
    setFormData({
      firstName: "",
      lastName: "",
      email: "",
      company: "",
      phone: "",
      message: "",
    });
  };

  return (
    <div className={styles.contactFormContainer}>
      <div className={styles.rightCol}>
        <ContactInfoCard />
      </div>
      <div className={styles.leftCol}>
        <h2 className={styles.heading}>Get in touch</h2>
        <p className={styles.subheading}>
          Our friendly team would love to hear from you.
        </p>

        <form className={styles.form} onSubmit={handleSubmit}>
          <div className={styles.row}>
            <div className={styles.field}>
              <label htmlFor="firstName">First Name</label>
              <input
                type="text"
                id="firstName"
                name="firstName"
                value={formData.firstName}
                onChange={handleChange}
                required
              />
            </div>
            <div className={styles.field}>
              <label htmlFor="lastName">Last Name</label>
              <input
                type="text"
                id="lastName"
                name="lastName"
                value={formData.lastName}
                onChange={handleChange}
                required
              />
            </div>
          </div>

          <div className={styles.row}>
            <div className={styles.field}>
              <label htmlFor="email">Email</label>
              <input
                type="email"
                id="email"
                name="email"
                value={formData.email}
                onChange={handleChange}
                required
              />
            </div>
            <div className={styles.field}>
              <label htmlFor="company">Your Company Name</label>
              <input
                type="text"
                id="company"
                name="company"
                value={formData.company}
                onChange={handleChange}
                required
              />
            </div>
          </div>

          <div className={styles.row}>
            <div className={styles.fieldFullWidth}>
              <label htmlFor="phone">Phone Number</label>
              <input
                type="tel"
                id="phone"
                name="phone"
                value={formData.phone}
                onChange={handleChange}
                required
              />
            </div>
          </div>

          <div className={styles.row}>
            <div className={styles.fieldFullWidth}>
              <label htmlFor="message">Message</label>
              <textarea
                className={styles.textareaWidth}
                id="message"
                name="message"
                rows="5"
                value={formData.message}
                onChange={handleChange}
                required
              ></textarea>
            </div>
          </div>

          <div className={styles.btnDiv}>
            <div className={styles.row}>
              <button type="submit" className={styles.buttonOne}>
                Send message
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default ContactForm;
