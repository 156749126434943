import React, { useEffect, useState } from "react";
import styles from "./Commitment.module.css";
import client from "../../sanityClient"; // Adjust the path as necessary
const Commitment = () => {
  const [pageData, setPageData] = useState(null);

  useEffect(() => {
    client
      .fetch(
        `*[_type == "commitmentPage"][0]{
          title,
          "cards": cards[] {
            _id,
            icon {
              asset->{
                url
              }
            },
            becauseText,
            dimension,
            description
          }
        }`
      )
      .then((data) => setPageData(data))
      .catch((error) => console.error(error));
  }, []);

  if (!pageData) {
    return <div></div>;
  }

  return (
    <div className={styles.commitmentContainer}>
      <h1 className={styles.title}>{pageData.title}</h1>
      <div className={styles.cardsContainer}>
        {pageData.cards.map((card) => (
          <div key={Math.random()} className={styles.card}>
            <div className={styles.icon}>
              {card.icon?.asset?.url && (
                <img
                  src={card.icon.asset.url}
                  // className={styles.icon}
                  alt="icon"
                />
              )}
            </div>
            <p className={styles.becauseText}>{card.becauseText}</p>
            <p className={styles.year}>{card.dimension}</p>
            <p className={styles.description}>{card.description}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Commitment;
