import React, { createContext, useState, useEffect } from 'react';
import client from '../components/sanityClient';

const DataContext = createContext();

const DataProvider = ({ children }) => {
  const [navbar, setNavbar] = useState(null); 
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const navData = await client.fetch(`
          *[_type == "navbar"]{
            logo{
              asset->{
                url
              }
            },
            navItems[]{
              label,
              slug,
              children[]{
                label,
                slug
              }
            }
          }
        `);

        setNavbar(navData[0]); // Assuming you're fetching one navbar document
        setLoading(false);
      } catch (error) {
        console.error("Error fetching navbar data:", error);
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  return (
    <DataContext.Provider value={{ navbar, loading }}>
      {children}
    </DataContext.Provider>
  );
};

export { DataContext, DataProvider };
