// import React from "react";
// import styles from "./WireShotsHero.module.css";
// import industryImage from "../image 4.png"; // Replace with the actual path to your image

// const WireShotsHero = () => {
//   return (
//     <section className={styles.heroContainer}>
//       <h1 className={styles.heroTitle}>More About Cut Wire Shots</h1>
//       <p className={styles.heroSubtitle}>
//         Discover how our high-quality cut wire shots provide superior
//         performance and unmatched benefits across a variety of industries.
//       </p>
//       <img
//         src={industryImage}
//         alt="Industries We Serve"
//         className={styles.heroImage}
//       />
//     </section>
//   );
// };

// export default WireShotsHero;

import React, { useEffect, useState } from "react";
import client from "../../sanityClient"; // Adjust the path as necessary
import styles from "./WireShotsHero.module.css";

const WireShotsHero = () => {
  const [heroData, setHeroData] = useState(null);

  useEffect(() => {
    const fetchHeroData = async () => {
      try {
        const data = await client.fetch(`
          *[_type == "wireShotsHero"][0] {
            title,
            subtitle,
            image {
              asset -> {
                url
              }
            }
          }
        `);
        setHeroData(data);
      } catch (error) {
        console.error("Error fetching hero data:", error);
      }
    };

    fetchHeroData();
  }, []);

  if (!heroData) return <p></p>;

  return (
    <section className={styles.heroContainer}>
      <h1 className={styles.heroTitle}>{heroData.title}</h1>
      <p className={styles.heroSubtitle}>{heroData.subtitle}</p>
      <img
        src={heroData.image?.asset?.url}
        alt="Industries We Serve"
        className={styles.heroImage}
      />
    </section>
  );
};

export default WireShotsHero;
