// import React from "react";
// import styles from "./CoreFocus.module.css";

// const CoreFocus = () => {
//   return (
//     <div className={styles.coreFocusContainer}>
//       <div className={styles.leftCol}>
//         <h2>Our Core Focus</h2>
//         <p>
//           We deal with cut wire shot day in and day out. They constitute the
//           original core DNA of our Company and we don’t do anything else. 100%
//           Focus! So it just makes sense that with a focus such as this, we have
//           applied our research over the years on improving the quality of our
//           product.
//         </p>
//       </div>
//       <div className={styles.rightCol}>
//         <table className={styles.table}>
//           <thead>
//             <tr>
//               <th>Shape</th>
//               <th>Description</th>
//               <th>Specifications</th>
//             </tr>
//           </thead>
//           <tbody>
//             <tr>
//               <td>As Cut</td>
//               <td>Cylindrical with Sharp Edges</td>
//               <td>SAE J441</td>
//             </tr>
//             <tr>
//               <td>Conditioned</td>
//               <td>Spherical with Rounded Edges</td>
//               <td>SAE J442</td>
//             </tr>
//             <tr>
//               <td>Double Conditioned</td>
//               <td>Highly Spherical with Smooth Edges</td>
//               <td>SAE J443</td>
//             </tr>
//           </tbody>
//         </table>
//       </div>
//     </div>
//   );
// };

// export default CoreFocus;

import React, { useEffect, useState } from "react";
import client from "../../sanityClient";
import styles from "./CoreFocus.module.css";

const CoreFocus = () => {
  const [coreFocus, setCoreFocus] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const query = `*[_type == "coreFocus"][0]{
          title,
          description,
          tableData[] {
            shape,
            description,
            specifications
          }
        }`;
        const data = await client.fetch(query);
        setCoreFocus(data);
      } catch (error) {
        console.error("Error fetching data from Sanity:", error);
      }
    };

    fetchData();
  }, []);

  if (!coreFocus) {
    return <div></div>;
  }

  return (
    <div className={styles.coreFocusContainer}>
      <div className={styles.leftCol}>
        <h2>{coreFocus.title}</h2>
        <p>{coreFocus.description}</p>
      </div>
      <div className={styles.rightCol}>
        <table className={styles.table}>
          <thead>
            <tr>
              <th>Shape</th>
              <th>Description</th>
              <th>Specifications</th>
            </tr>
          </thead>
          <tbody>
            {coreFocus.tableData.map((item, index) => (
              <tr key={index}>
                <td>{item.shape}</td>
                <td>{item.description}</td>
                <td>{item.specifications}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default CoreFocus;
