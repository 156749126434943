import React, { useEffect, useState } from "react";
import styles from "./Advantage.module.css";
import client from "../../sanityClient";

const Advantage = () => {
  const [advantages, setAdvantages] = useState([]);
  const [section, setSection] = useState({ title: "", description: "" });
  const [loading, setLoading] = useState(true); // Add a loading state

  useEffect(() => {
    const fetchData = async () => {
      try {
        const [sectionData] = await Promise.all([
          client.fetch(`*[_type == "advantageSection"][0]{
            sectionTitle,
            sectionDescription,
            advantages[] {
              icon {
                asset->{
                  _id,
                  url
                }
              },
              title,
              description
            }
          }`),
        ]);

        // Extract section and advantages from the fetched data
        setSection({
          title: sectionData.sectionTitle,
          description: sectionData.sectionDescription,
        });
        setAdvantages(sectionData.advantages);
        setLoading(false); // Data has been loaded
      } catch (error) {
        console.error("Error fetching data:", error);
        setLoading(false); // Data loading failed
      }
    };

    fetchData();
  }, []);

  if (loading) return <div></div>; // Show a loading message

  return (
    <div className={styles.container}>
      <h2 className={styles.title}>{section.title}</h2>
      <p className={styles.description}>{section.description}</p>
      <div className={styles.cardsContainer}>
        {advantages.length > 0 ? (
          advantages.map((adv, index) => (
            <div className={styles.card} key={index}>
              <div className={styles.icon}>
                <img
                  src={adv.icon.asset.url}
                  alt={adv.title}
                  className={styles.iconImage}
                />
              </div>
              <h3 className={styles.cardTitle}>{adv.title}</h3>
              <p className={styles.cardDescription}>{adv.description}</p>
            </div>
          ))
        ) : (
          <p>No advantages found</p>
        )}
      </div>
    </div>
  );
};

export default Advantage;
