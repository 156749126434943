import React, { useEffect, useState } from "react";
import styles from "./Footer.module.css";
import client from "../../sanityClient"; // Adjust the path to your sanityClient file
import imageUrlBuilder from "@sanity/image-url";
import btnIcon from "./Frame 1000007949.png";

const builder = imageUrlBuilder(client);

function urlFor(source) {
  return builder.image(source);
}

const Footer = () => {
  const [footerData, setFooterData] = useState(null);

  useEffect(() => {
    client
      .fetch(
        `*[_type == "footer"]{
          logo,
          contactInfo[]{
            icon,
            text
          },
          mainPages[]{
            title,
            link
          },
          products[]{
            title,
            link
          },
          company[]{
            title,
            link
          },
          resources[]{
            title,
            link
          },
          copyrightText,
          bottomLinks[]{
            title,
            link
          },
          button {
            text,
            link
          }
        }[0]`
      )
      .then((data) => {
        setFooterData(data);
      })
      .catch(console.error);
  }, []);

  if (!footerData) return <div></div>;

  return (
    <footer className={styles.footer}>
      <div className={styles.row}>
        <div className={styles.leftColumn}>
          <div className={styles.logo}>
            {footerData.logo && (
              <img src={urlFor(footerData.logo).url()} alt="Logo" />
            )}
          </div>
          {footerData.contactInfo.map((info, index) => (
            <div key={index} className={styles.contact}>
              {info.icon && (
                <img src={urlFor(info.icon).url()} alt="Contact Icon" />
              )}
              <span dangerouslySetInnerHTML={{ __html: info.text }} />
            </div>
          ))}
          {footerData.button && (
            <a href={footerData.button.link} className={styles.btn}>
              <img src={btnIcon} alt="Button Icon" className={styles.btnIcon} />
              <span className={styles.btnText}>{footerData.button.text}</span>
            </a>
          )}
        </div>
        <div className={styles.rightColumn}>
          <div className={styles.column}>
            <h4>Company</h4>
            <ul>
              {footerData.company.length > 0 ? (
                footerData.company.map((company, index) => (
                  <li key={index}>
                    <a href={company.link}>{company.title}</a>
                  </li>
                ))
              ) : (
                <li>No company information available</li>
              )}
            </ul>
          </div>
          <div className={styles.column}>
            <h4>Products</h4>
            <ul>
              {footerData.products.length > 0 ? (
                footerData.products.slice(0, 3).map((product, index) => (
                  <li key={index}>
                    <a href={product.link}>{product.title}</a>
                  </li>
                ))
              ) : (
                <li>No products available</li>
              )}
            </ul>
          </div>
          <div className={styles.column}>
            <h4>&nbsp;</h4> {/* Empty header for alignment */}
            <ul>
              {footerData.products.length > 3
                ? footerData.products.slice(3, 6).map((product, index) => (
                    <li key={index}>
                      <a href={product.link}>{product.title}</a>
                    </li>
                  ))
                : null}
            </ul>
          </div>
        </div>
      </div>
      <hr className={styles.hr} />
      <div className={styles.bottomRow}>
        <div className={styles.leftBottom}>
          <span className={styles.copyrightText}>
            {footerData.copyrightText || "© 2024 Your Company"}
          </span>
        </div>
        <div className={styles.rightBottom}>
          {footerData.bottomLinks.length > 0 ? (
            footerData.bottomLinks.map((link, index) => (
              <a key={index} href={link.link}>
                {link.title}
              </a>
            ))
          ) : (
            <span>No bottom links available</span>
          )}
        </div>
      </div>
    </footer>
  );
};

export default Footer;
