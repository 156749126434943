// import React from "react";
// import styles from "./AboutHero.module.css";
// import img from "./image 4.png";

// const AboutHero = () => {
//   return (
//     <section className={styles.aboutHero}>
//       <ul className={styles.topList}>
//         <li>Quality</li>
//         <li>Precision</li>
//         <li>Reliability</li>
//       </ul>
//       <h1 className={styles.mainHeading}>High Quality comes from Experience</h1>
//       <p className={styles.description}>
//         You are the expert on your products and we are your reliable partner to
//         make your product perfect. At Suntec Enterprises, we have been providing
//         reliable solutions for shot peening and shot blasting requirements since
//         2002.
//       </p>
//       <div className={styles.imageContainer}>
//         <img src={img} alt="About Hero" className={styles.heroImage} />
//       </div>
//     </section>
//   );
// };

// export default AboutHero;

import React, { useEffect, useState } from "react";
import client from "../sanityClient";
import styles from "./AboutHero.module.css";

const AboutHero = () => {
  const [content, setContent] = useState(null);

  useEffect(() => {
    client
      .fetch(
        `*[_type == "aboutHeroS"]{
          topList,
          mainHeading,
          description,
          "imageUrl": image.asset->url
        }`
      )
      .then((data) => setContent(data[0]))
      .catch(console.error);
  }, []);

  if (!content) return <div></div>;

  return (
    <div className={styles.wrapper}>
      <section className={styles.aboutHero}>
        <ul className={styles.topList}>
          {content.topList.map((item, index) => (
            <li key={index}>{item}</li>
          ))}
        </ul>
        <h1 className={styles.mainHeading}>{content.mainHeading}</h1>
        <p className={styles.description}>{content.description}</p>
        <div className={styles.imageContainer}>
          <img
            src={content.imageUrl}
            alt="About Hero"
            className={styles.heroImage}
          />
        </div>
      </section>
    </div>
  );
};

export default AboutHero;
