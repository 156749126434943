// // VideoSlider.js
// import React, { useState, useEffect, useRef } from "react";
// import styles from "./Video.module.css";
// import vid from "./vid.mp4";
// import playButton from "./Group 1171274891 (1).png";
// const Video = () => {
//   const [currentVideo, setCurrentVideo] = useState(0);
//   const videoRefs = useRef([]);
//   const videos = [
//     { src: vid, text: "Exceptional quality that can't be beaten" },
//     { src: vid, text: "Exceptional quality that can't be beaten" },
//     { src: vid, text: "Exceptional quality that can't be beaten" },
//   ];

//   useEffect(() => {
//     const interval = setInterval(() => {
//       setCurrentVideo((prevVideo) => (prevVideo + 1) % videos.length);
//     }, 5000); // Slide every 5 seconds
//     return () => clearInterval(interval);
//   }, [videos.length]);

//   const handlePlay = (index) => {
//     videoRefs.current[index].play();
//   };

//   return (
//     <div className={styles.sliderContainer}>
//       {videos.map((video, index) => (
//         <div
//           key={index}
//           className={`${styles.videoContainer} ${
//             index === currentVideo ? styles.active : ""
//           }`}
//         >
//           <video
//             ref={(el) => (videoRefs.current[index] = el)}
//             src={video.src}
//             className={styles.video}
//             muted
//             loop
//           />
//           <div className={styles.overlayText}>{video.text}</div>
//           <button
//             className={styles.playButton}
//             onClick={() => handlePlay(index)}
//           >
//             <img src={playButton} />
//           </button>
//         </div>
//       ))}
//     </div>
//   );
// };

// export default Video;

import React, { useState, useEffect, useRef } from "react";
import styles from "./Video.module.css";
import playButton from "./playBtn.png";
import pauseButton from "./pauseBtn.png";
import client from "../../sanityClient";

const VideoSlider = () => {
  const [videos, setVideos] = useState([]);
  const [currentVideo, setCurrentVideo] = useState(0);
  const [isPlaying, setIsPlaying] = useState(false);
  const videoRefs = useRef([]);
  const slideIntervalRef = useRef(null);

  useEffect(() => {
    const fetchVideos = async () => {
      const query = `*[_type == "videoSlider"]{
        videos[]{
          "src": videoFile.asset->url,
          text
        }
      }`;
      const result = await client.fetch(query);
      if (result.length > 0) {
        setVideos(result[0].videos);
      }
    };

    fetchVideos();
  }, []);

  useEffect(() => {
    if (videos.length === 0) return;

    const startAutoSlide = () => {
      slideIntervalRef.current = setInterval(() => {
        setCurrentVideo((prevVideo) => (prevVideo + 1) % videos.length);
      }, 5000); // Slide every 5 seconds
    };

    startAutoSlide();

    return () => clearInterval(slideIntervalRef.current);
  }, [videos]);

  const handlePlayPause = () => {
    if (isPlaying) {
      videoRefs.current[currentVideo].pause();
      setIsPlaying(false);
      // Resume auto-slide
      slideIntervalRef.current = setInterval(() => {
        setCurrentVideo((prevVideo) => (prevVideo + 1) % videos.length);
      }, 5000);
    } else {
      videoRefs.current[currentVideo].play();
      setIsPlaying(true);
      // Stop auto-slide
      clearInterval(slideIntervalRef.current);
    }
  };

  const handleIndicatorClick = (index) => {
    setCurrentVideo(index);
    setIsPlaying(true);
    videoRefs.current.forEach((video, i) => {
      if (i !== index) video.pause();
    });
  };

  return (
    <div className={styles.sliderContainer}>
      {videos.map((video, index) => (
        <div
          key={index}
          className={`${styles.videoContainer} ${
            index === currentVideo ? styles.active : ""
          }`}
        >
          <video
            ref={(el) => (videoRefs.current[index] = el)}
            src={video.src}
            className={styles.video}
            muted
            loop
          />
          <div className={styles.overlayText}>{video.text}</div>
          <button className={styles.playButton} onClick={handlePlayPause}>
            <img
              className={styles.btnControl}
              src={isPlaying ? pauseButton : playButton}
              alt="Play/Pause button"
            />
          </button>
        </div>
      ))}
      <div className={styles.indicators}>
        {videos.map((_, index) => (
          <span
            key={index}
            className={`${styles.indicator} ${
              index === currentVideo ? styles.activeIndicator : ""
            }`}
            onClick={() => handleIndicatorClick(index)}
          />
        ))}
      </div>
    </div>
  );
};

export default VideoSlider;
