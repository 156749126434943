// import React from "react";
// import styles from "./WireShotHistory.module.css";
// import historyImage from "./image 29.png"; // Replace with your actual image path

// const WireShotHistory = () => {
//   return (
//     <div className={styles.historyContainer}>
//       <div className={styles.leftCol}>
//         <h2 className={styles.heading}>History of Cut Wire Shots</h2>
//         <p className={styles.description}>
//           The use of cut wire shots dates back to the early 20th century when
//           the need for effective surface treatment methods became critical in
//           manufacturing. Over the decades, technological advancements have
//           improved the production process, making cut wire shots more efficient
//           and reliable. Today, they are an integral part of modern manufacturing
//           and maintenance practices, offering superior performance in various
//           applications.
//         </p>
//       </div>
//       <div className={styles.rightCol}>
//         <img
//           src={historyImage}
//           alt="History of Cut Wire Shots"
//           className={styles.image}
//         />
//       </div>
//     </div>
//   );
// };

// export default WireShotHistory;

import React, { useEffect, useState } from "react";
import client from "../../sanityClient"; // Adjust the import path as needed
import imageUrlBuilder from "@sanity/image-url"; // Import image URL builder
import styles from "./WireShotHistory.module.css";

// Create a builder instance
const builder = imageUrlBuilder(client);

// Function to get the image URL
function urlFor(source) {
  return builder.image(source);
}

const WireShotHistory = () => {
  const [data, setData] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const query = '*[_type == "wireShotHistory"][0]'; // Adjust the query as needed
        const result = await client.fetch(query);
        setData(result);
      } catch (error) {
        console.error("Failed to fetch data from Sanity", error);
      }
    };

    fetchData();
  }, []);

  if (!data) {
    return <p></p>;
  }

  return (
    <div className={styles.historyContainer}>
      <div className={styles.leftCol}>
        <h2 className={styles.heading}>{data.heading}</h2>
        <p className={styles.description}>{data.description}</p>
      </div>
      <div className={styles.rightCol}>
        <img
          src={urlFor(data.image).url()} // Use urlFor to get the image URL
          alt={data.heading}
          className={styles.image}
        />
      </div>
    </div>
  );
};

export default WireShotHistory;
