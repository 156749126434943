import React, { useEffect, useState } from "react";
import styles from "./ContactInfoCard.module.css";
import client from "../../sanityClient";
import imageUrlBuilder from "@sanity/image-url";

// Set up the Sanity image URL builder
const builder = imageUrlBuilder(client);

const urlFor = (source) => {
  return builder.image(source);
};

const ContactInfoCard = () => {
  const [contactInfo, setContactInfo] = useState(null);

  useEffect(() => {
    const fetchContactInfo = async () => {
      const query = `*[_type == "contactInfoCard"]{
        logo,
        squareImg,
        address,
        locationIcon,
        email,
        emailIcon,
        phone,
        phoneIcon,
        whatsapp,
        whatsappIcon
      }[0]`;
      const data = await client.fetch(query);
      setContactInfo(data);
    };
    fetchContactInfo();
  }, []);

  if (!contactInfo) return <div></div>;

  return (
    <div className={styles.card}>
      <div className={styles.logoDiv}>
        {/* Use the image URL builder for logo and square image */}
        <img
          src={urlFor(contactInfo.logo).url()}
          alt="Company Logo"
          className={styles.logo}
        />
        <img
          className={styles.squareImg}
          src={urlFor(contactInfo.squareImg).url()}
          alt="cardImg"
        />
      </div>
      <div className={styles.infoCard}>
        <div className={styles.infoRow}>
          <img
            src={urlFor(contactInfo.locationIcon).url()}
            alt="Location Icon"
          />
          <span
            className={styles.infoText}
            dangerouslySetInnerHTML={{ __html: contactInfo.address }}
          />
        </div>

        <div className={styles.infoRow}>
          <img src={urlFor(contactInfo.emailIcon).url()} alt="Email Icon" />
          <span className={styles.infoText}>{contactInfo.email}</span>
        </div>

        <div className={styles.infoRow}>
          <img src={urlFor(contactInfo.phoneIcon).url()} alt="Phone Icon" />
          <span className={styles.infoText}>{contactInfo.phone}</span>
        </div>

        <div className={styles.infoRow}>
          <img
            src={urlFor(contactInfo.whatsappIcon).url()}
            alt="WhatsApp Icon"
          />
          <span className={styles.infoText}>{contactInfo.whatsapp}</span>
        </div>
      </div>
    </div>
  );
};

export default ContactInfoCard;
