// import React from "react";
// import styles from "./SQuality.module.css";

// const SQuality = () => {
//   const cardData = [
//     {
//       id: 1,
//       text: "Quality Management system: Two way quality check",
//       icon: "🛡️",
//     },
//     {
//       id: 2,
//       text: "Surface Finishing: Uniform and consistent results",
//       icon: "✨",
//     },
//     { id: 3, text: "Cost Efficiency: Reduces operational costs", icon: "💰" },
//     { id: 4, text: "Durability: Ensures longer life", icon: "🏋️" },
//     { id: 5, text: "Precision: Accurate size and shape", icon: "📏" },
//     { id: 6, text: "Reliability: Consistent performance", icon: "✔️" },
//   ];

//   return (
//     <div className={styles.sQualityContainer}>
//       <h1 className={styles.title}>S+ Quality</h1>
//       <p className={styles.description}>
//         Cut wire shots provide uniform size and shape, ensuring consistent and
//         superior surface finishing. They also offer longer life and higher
//         durability, reducing operational costs significantly.
//       </p>
//       <div className={styles.cardContainer}>
//         {cardData.map((card) => (
//           <div key={card.id} className={styles.card}>
//             <div className={styles.icon}>{card.icon}</div>
//             <p className={styles.cardText}>{card.text}</p>
//           </div>
//         ))}
//       </div>
//     </div>
//   );
// };

// export default SQuality;

import React, { useEffect, useState } from "react";
import styles from "./SQuality.module.css";
import imageUrlBuilder from "@sanity/image-url";
import client from "../sanityClient";

// Initialize the image URL builder
const builder = imageUrlBuilder(client);
const urlFor = (source) => builder.image(source);

const SQuality = () => {
  const [data, setData] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const query = `*[_type == "sQuality"][0]{
          title,
          description,
          cards[]{
            text,
            icon{
              asset->{
                _id,
                url
              }
            }
          }
        }`;
        const result = await client.fetch(query);
        setData(result);
      } catch (error) {
        console.error("Error fetching data from Sanity:", error);
      }
    };

    fetchData();
  }, []);

  if (!data) {
    return <div></div>;
  }

  return (
    <div className={styles.sQualityContainer}>
      <h1 className={styles.title}>{data.title}</h1>
      <p className={styles.description}>{data.description}</p>
      <div className={styles.cardContainer}>
        {data.cards.map((card, index) => (
          <div key={index} className={styles.card}>
            <div className={styles.icon}>
              {card.icon?.asset?.url && (
                <img src={urlFor(card.icon.asset.url).url()} alt="Card icon" />
              )}
            </div>
            <p className={styles.cardText}>{card.text}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default SQuality;
