// import React from "react";
// import styles from "./ChooseUs.module.css";
// import img from "./image 29.png";
// import img2 from "./fi_686348.png";
// const ChooseUs = () => {
//   return (
//     <div className={styles.chooseUsContainer}>
//       <div className={styles.leftCol}>
//         <h2 className={styles.heading}>Why Choose Us?</h2>
//         <p className={styles.description}>
//           Trusted by industry leaders, our commitment to excellence and customer
//           satisfaction ensures you receive only the highest quality products and
//           services.
//         </p>
//         <button className={styles.ctaButton}>Learn More</button>
//         <img src={img} alt="Why Choose Us" className={styles.image} />
//       </div>
//       <div className={styles.rightCol}>
//         <div className={styles.card}>
//           <div className={styles.icon}>
//             <img src={img2} />
//           </div>
//           <h3 className={styles.cardTitle}>Customer Satisfaction</h3>
//           <p className={styles.cardDescription}>
//             Cut wire shots are durable and can be used multiple times without
//             wearing out quickly.
//           </p>
//         </div>
//         <div className={styles.card}>
//           <div className={styles.icon}>
//             <img src={img2} />
//           </div>
//           <h3 className={styles.cardTitle}>Quality Assurance</h3>
//           <p className={styles.cardDescription}>
//             Our products are rigorously tested to meet the highest standards.
//           </p>
//         </div>
//         <div className={styles.card}>
//           <div className={styles.icon}>
//             <img src={img2} />
//           </div>
//           <h3 className={styles.cardTitle}>Secure Service</h3>
//           <p className={styles.cardDescription}>
//             We ensure that your data and transactions are protected at all
//             times.
//           </p>
//         </div>
//         <div className={styles.card}>
//           <div className={styles.icon}>
//             <img src={img2} />
//           </div>
//           <h3 className={styles.cardTitle}>Professional Support</h3>
//           <p className={styles.cardDescription}>
//             Our team is here to support you every step of the way.
//           </p>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default ChooseUs;

import React, { useEffect, useState } from "react";
import styles from "./ChooseUs.module.css";
import client from "../../sanityClient"; // Adjust the path as needed
import { Link } from "react-router-dom";

const ChooseUs = () => {
  const [data, setData] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const query = `*[_type == "chooseUs"][0] {
          heading,
          description,
          ctaButtonText,
          mainImage {
            asset->{
              url
            }
          },
          cards[] {
            icon {
              asset->{
                url
              }
            },
            title,
            description
          }
        }`;
        const result = await client.fetch(query);
        setData(result);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  if (!data) return <div></div>;

  return (
    <div className={styles.chooseUsContainer}>
      <div className={styles.leftCol}>
        <h2 className={styles.heading}>{data.heading}</h2>
        <p className={styles.description}>{data.description}</p>
        <div className={styles.btnDiv}>
          <Link className={styles.ctaButton} to="/contact-us">
            {data.ctaButtonText}
          </Link>
        </div>
        {data.mainImage && (
          <img
            src={data.mainImage.asset.url}
            alt="Why Choose Us"
            className={styles.image}
          />
        )}
      </div>
      <div className={styles.rightCol}>
        {data.cards.map((card, index) => (
          <div key={index} className={styles.card}>
            <div className={styles.icon}>
              {card.icon && <img src={card.icon.asset.url} alt={card.title} />}
            </div>
            <h3 className={styles.cardTitle}>{card.title}</h3>
            <p className={styles.cardDescription}>{card.description}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default ChooseUs;
