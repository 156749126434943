// import React from "react";
// import styles from "./WireShotDescription.module.css";

// const WireShotDescription = () => {
//   return (
//     <div className={styles.container}>
//       <h2 className={styles.title}>What are Cut Wire Shots</h2>
//       <hr className={styles.divider} />
//       <p className={styles.description}>
//         Cut wire shots are cylindrical particles made from high-quality wire,
//         which is cut into lengths equal to its diameter. These particles are
//         further processed to create various shapes and sizes, depending on the
//         application requirements. Cut wire shots are used extensively in shot
//         peening, cleaning, deburring, and surface preparation processes across
//         numerous industries.
//       </p>
//     </div>
//   );
// };

// export default WireShotDescription;

import React, { useEffect, useState } from "react";
import client from "../../sanityClient";
import styles from "./WireShotDescription.module.css";

const WireShotDescription = () => {
  const [content, setContent] = useState(null);

  useEffect(() => {
    const fetchContent = async () => {
      try {
        const query = `*[_type == "wireShotDescription"][0]`;
        const data = await client.fetch(query);
        setContent(data);
      } catch (error) {
        console.error("Failed to fetch content", error);
      }
    };

    fetchContent();
  }, []);

  if (!content) {
    return <div></div>;
  }

  return (
    <div className={styles.container}>
      <h2 className={styles.title}>{content.title}</h2>
      <hr className={styles.divider} />
      <p className={styles.description}>{content.description}</p>
    </div>
  );
};

export default WireShotDescription;
