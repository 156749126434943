import React from "react";
import styles from "./AdditionalInfo.module.css";

const AdditionalInfo = ({ additionalInfo, title }) => {
  if (!additionalInfo) return null;

  return (
    <div className={styles.container}>
      <div className={styles.leftCol}>
        <h2>Why {title} Cut Wire Shot</h2>
        <p>{additionalInfo.why}</p>
        <hr />
        <h2>Long Life Less Waste</h2>
        <p>{additionalInfo.longLife}</p>
        <hr />
        <h2>It’s Recyclable</h2>
        <p>{additionalInfo.recyclable}</p>
        <hr />
        <h2>It’s Non-combustible</h2>
        <p>{additionalInfo.nonCombustible}</p>
        <hr />
        <h2>It Works Well as Plating Filler</h2>
        <p>{additionalInfo.platingFiller}</p>
      </div>
      <div className={styles.rightCol}>
        <h2 className={styles.additionalHead}>Additional Information</h2>
        <div className={styles.infoSection}>
          <div className={styles.firstDiv}>
            <h3 className={styles.addSubHeader}>Compatible Equipment</h3>
            <ul>
              {additionalInfo.equipment.map((item, index) => (
                <li key={index}>{item}</li>
              ))}
            </ul>
          </div>
          <div className={styles.secondDiv}>
            <h3 className={styles.addSubHeader}>Shape</h3>
            <ul>
              <li>{additionalInfo.shape}</li>
            </ul>
          </div>
        </div>
        <div className={styles.infoSection}>
          <div className={styles.firstDiv}>
            <h3 className={styles.addSubHeader}>Industry Specifications</h3>
            <ul>
              {additionalInfo.industrySpecifications.map((item, index) => (
                <li key={index}>{item}</li>
              ))}
            </ul>
          </div>
          <div className={styles.secondDiv}>
            <h3 className={styles.addSubHeader}>Bulk Density</h3>
            <ul>
              <li>{additionalInfo.bulkDensity}</li>
            </ul>
          </div>
        </div>
        <div className={styles.infoSection}>
          <div className={styles.firstDiv}>
            <h3 className={styles.addSubHeader}>Alloys</h3>
            <ul>
              {additionalInfo.alloy.map((item, index) => (
                <li key={index}>{item}</li>
              ))}
            </ul>
          </div>
          <div className={styles.secondDiv}>
            <h3 className={styles.addSubHeader}>Packaging</h3>
            <ul>
              <li>{additionalInfo.packaging}</li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AdditionalInfo;
