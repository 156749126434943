import React from "react";
import styles from "./Application.module.css";
import { urlFor } from "../../sanityClient";

const Application = ({ applications }) => {
  return (
    <div className={styles.container}>
      <h1 className={styles.header}>Markets & Applications</h1>
      <div className={styles.cards}>
        {applications?.map((app) => (
          <div key={app.title} className={styles.card}>
            <div className={styles.icon}>
              <img src={urlFor(app.icon).url()} alt={app.title} />
            </div>
            <div className={styles.text}>{app.title}</div>
            {/* <p className={styles.description}>{app.description}</p> */}
          </div>
        ))}
      </div>
    </div>
  );
};

export default Application;
