//

import React, { useEffect, useState } from "react";
import styles from "./ContactBanner.module.css";
import client from "../../sanityClient"; // Import your Sanity client
import imageUrlBuilder from "@sanity/image-url";

// Create a builder to build the image URL from Sanity
const builder = imageUrlBuilder(client);
function urlFor(source) {
  return builder.image(source);
}

const ContactBanner = () => {
  const [contactBannerData, setContactBannerData] = useState(null);

  // Fetch the data from Sanity
  useEffect(() => {
    client
      .fetch(
        `*[_type == "contactBanner"][0]{
          heading,
          subheading,
          "mainImage": mainImage.asset->url,
          "imgOne": imgOne.asset->url,
          "imgTwo": imgTwo.asset->url
        }`
      )
      .then((data) => setContactBannerData(data))
      .catch(console.error);
  }, []);

  if (!contactBannerData) return <div></div>;

  return (
    <div className={styles.banner}>
      <div className={styles.leftSection}>
        <h1 className={styles.heading}>{contactBannerData.heading}</h1>
        <p className={styles.subheading}>{contactBannerData.subheading}</p>
      </div>
      <div className={styles.rightSection}>
        <img
          src={urlFor(contactBannerData.mainImage).url()}
          alt="Contact Us"
          className={styles.image}
        />
      </div>
      <div className={styles.imgOne}>
        <img src={urlFor(contactBannerData.imgOne).url()} alt="Ellipse 6" />
      </div>
      <div className={styles.imgTwo}>
        <img src={urlFor(contactBannerData.imgTwo).url()} alt="Ellipse 7" />
      </div>
    </div>
  );
};

export default ContactBanner;
