import React, { useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import AdditionalInfo from "./additionalInfo/AdditionalInfo";
import ConditionLevel from "./conditionLevel/ConditionLevel";
import Performance from "./performance/Performance";
import Application from "./application/Application";
import ProductSlider from "./productSlider/ProductSlider";
import client, { urlFor } from "../sanityClient";
import ExploreProduct from "./exploreProduct/ExploreProduct";

const ProductDetail = () => {
  const { metalName } = useParams();
  const location = useLocation();
  const { productData: stateProductData } = location.state || {};
  const [product, setProduct] = useState(stateProductData || null);

  useEffect(() => {
    if (!stateProductData) {
      client
        .fetch(
          `*[_type == "productPageContent" && products[].metalName == $metalName][0] {
            products[metalName == $metalName][0] {
              title,
              description,
              imgSrc {
                asset -> {
                  _id
                }
              },
              verticalImage,
              verticalImageDescription,
              productbannerDescription,
              additionalInfo,
              conditionLevels[] {
                title,
                descriptionCondition,
                image {
                  asset -> {
                    _id
                  }
                },
                altText
              },
              performance[] {
                icon,
                title,
                description
              },
              applications[] {
                title,
                icon {
                  asset -> {
                    _id
                  }
                }
              }
            }
          }`,
          { metalName }
        )
        .then((data) => {
          if (data && data.products) {
            setProduct(data.products);
          } else {
            setProduct(null);
          }
        })
        .catch((error) => {
          console.error("Error fetching product data:", error);
          setProduct(null);
        });
    }
  }, [metalName, stateProductData]);

  if (!product) {
    return <div></div>;
  }

  return (
    <div>
      <ProductSlider
        title={product.title}
        imgSrc={product.imgSrc}
        productbannerDescription={product.productbannerDescription}
      />
      <AdditionalInfo
        additionalInfo={product.additionalInfo}
        title={product.title}
      />
      <ConditionLevel
        conditionLevels={product.conditionLevels}
        verticalImage={product.verticalImage}
        verticalImageDescription={product.verticalImageDescription}
      />

      <Performance performance={product.performance} />
      <Application applications={product.applications} />
      <ExploreProduct currentProduct={metalName} />
    </div>
  );
};

export default ProductDetail;
