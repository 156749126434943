// import React from "react";
// import styles from "./AboutCommitment.module.css";
// import img from "./image 29.png";

// const AboutCommitment = () => {
//   return (
//     <div className={styles.container}>
//       <div className={styles.leftCol}>
//         <h2 className={styles.heading}>Our Quality is Our Commitment</h2>
//         <p className={styles.description}>
//           At Suntec, quality is as important to us as it is to you. When it
//           comes to quality, we ourselves are our harshest critic. We hold
//           ourselves to all specified standards. At every stage of production,
//           our abrasive media are subject to inspection by our own QM lab, which
//           is certified according to the quality assurance system of ISO
//           9001:20015. Since we have been pulling the wire for our media
//           ourselves since 2002, we are self-sufficient and can guarantee the
//           dependability of our deliveries.
//         </p>
//       </div>
//       <div className={styles.rightCol}>
//         <img src={img} alt="Commitment Image" className={styles.image} />
//       </div>
//     </div>
//   );
// };

// export default AboutCommitment;

import React, { useEffect, useState } from "react";
import styles from "./AboutCommitment.module.css";
import client from "../../sanityClient";

const AboutCommitment = () => {
  const [content, setContent] = useState(null);

  useEffect(() => {
    client
      .fetch(
        `*[_type == "aboutCommitment"]{
          heading,
          description,
          "imageUrl": image.asset->url
        }[0]`
      )
      .then((data) => setContent(data))
      .catch(console.error);
  }, []);

  if (!content) {
    return <div></div>;
  }

  return (
    <div className={styles.container}>
      <div className={styles.leftCol}>
        <h2 className={styles.heading}>{content.heading}</h2>
        <p className={styles.description}>{content.description}</p>
      </div>
      <div className={styles.rightCol}>
        <img
          src={content.imageUrl}
          alt="Commitment Image"
          className={styles.image}
        />
      </div>
    </div>
  );
};

export default AboutCommitment;
