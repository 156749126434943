// import React from "react";
// import styles from "./WireShotApplication.module.css";
// import icon from "./fi_2511449.png"; // Ensure this path is correct

// const WireShotApplication = () => {
//   const cards = [
//     {
//       icon: icon,
//       title: "Higher Energy",
//       description:
//         "Embracing the latest technological advancements, we continuously innovate to improve our products and services. We help you receive cutting-edge solutions.",
//     },
//     {
//       icon: icon,
//       title: "Precision Engineering",
//       description:
//         "Our products are engineered with the highest precision, ensuring that you receive reliable and durable solutions for all your needs.",
//     },
//     {
//       icon: icon,
//       title: "Global Standards",
//       description:
//         "We adhere to global standards, ensuring that our products meet the rigorous demands of the international market.",
//     },
//     {
//       icon: icon,
//       title: "Innovative Solutions",
//       description:
//         "We are committed to bringing you the most innovative solutions that push the boundaries of what is possible.",
//     },
//   ];

//   return (
//     <div className={styles.container}>
//       <h2 className={styles.heading}>Applications of Cut Wire Shots</h2>
//       <div className={styles.cardsContainer}>
//         {cards.map((card, index) => (
//           <div key={index} className={styles.card}>
//             <img src={card.icon} alt={card.title} className={styles.icon} />
//             <h3 className={styles.cardTitle}>{card.title}</h3>
//             <p className={styles.cardDescription}>{card.description}</p>
//           </div>
//         ))}
//       </div>
//     </div>
//   );
// };

// export default WireShotApplication;

import React, { useEffect, useState } from "react";
import styles from "./WireShotApplication.module.css";
import client from "../../sanityClient"; // Ensure this path is correct

const WireShotApplication = () => {
  const [cards, setCards] = useState([]);

  useEffect(() => {
    // Fetch data from Sanity
    client
      .fetch(
        `*[_type == "wireShotApplication"]{
          icon{
            asset->{
              url
            }
          },
          title,
          description
        }`
      )
      .then((data) => setCards(data))
      .catch((error) =>
        console.error("Failed to fetch data from Sanity:", error)
      );
  }, []);

  return (
    <div className={styles.container}>
      <h2 className={styles.heading}>Applications of Cut Wire Shots</h2>
      <div className={styles.cardsContainer}>
        {cards.map((card, index) => (
          <div key={index} className={styles.card}>
            <img
              src={card.icon?.asset?.url}
              alt={card.title}
              className={styles.icon}
            />
            <h3 className={styles.cardTitle}>{card.title}</h3>
            <p className={styles.cardDescription}>{card.description}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default WireShotApplication;
