import React, { useEffect, useState } from "react";
import styles from "./Benefit.module.css";
import client from "../../sanityClient"; // Import the Sanity client
import imageUrlBuilder from "@sanity/image-url";
import { Link } from "react-router-dom";

const builder = imageUrlBuilder(client);

function urlFor(source) {
  return builder.image(source);
}

const Benefit = () => {
  const [benefits, setBenefits] = useState([]);
  const [heading, setHeading] = useState("");
  const [description, setDescription] = useState("");
  const [buttonText, setButtonText] = useState("");

  useEffect(() => {
    const fetchData = async () => {
      try {
        const query = `*[_type == "benefit"]`;
        const data = await client.fetch(query);

        if (data.length > 0) {
          const { heading, description, buttonText, benefits } = data[0];
          setHeading(heading || "");
          setDescription(description || "");
          setBenefits(benefits || []);
          setButtonText(buttonText || "");
        }
      } catch (error) {
        console.error("Error fetching data from Sanity:", error);
      }
    };

    fetchData();
  }, []);

  return (
    <div className={styles.container}>
      <div className={styles.leftCol}>
        <h2 className={styles.headingText}>{heading}</h2>
        <p className={styles.desc}>{description}</p>
        <div className={styles.btnDiv}>
          <Link className={styles.button} to="/contact-us">
            {buttonText}
          </Link>
        </div>
      </div>
      <div className={styles.rightCol}>
        {benefits.map((benefit, index) => (
          <div key={index} className={styles.benefitRow}>
            {benefit.tickImageUrl?.asset && (
              <span className={styles.icon}>
                <img
                  src={urlFor(benefit.tickImageUrl.asset).url()}
                  alt="Checkmark"
                />
              </span>
            )}
            <p className={styles.benefitText}>{benefit.text}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Benefit;
