import React from "react";
import styles from "./ConditionLevel.module.css";
import { urlFor } from "../../sanityClient";

const ConditionLevel = ({
  conditionLevels,
  verticalImage,
  verticalImageDescription,
}) => {
  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <h1 className={styles.title}>Conditioning Levels</h1>
        <div className={styles.secondDiv}>
          <p className={styles.description}>
            Cut wire is typically available at various conditioning levels.
            Conditioning improves its durability, but also makes sure to reduce
            risks of denting parts.
          </p>
        </div>
      </div>

      <div className={styles.content}>
        <div className={styles.leftColumn}>
          {conditionLevels && conditionLevels.length > 0 ? (
            conditionLevels.map((level, index) => (
              <div key={index} className={styles.card}>
                <img
                  src={
                    level.image
                      ? urlFor(level.image).url()
                      : "fallback-image-url"
                  } // Ensure level.image is passed correctly
                  className={styles.img2}
                  alt={level.altText || "Condition Level Icon"}
                />
                <h2 className={styles.cardTitle}>{level.title}</h2>
                <p className={styles.cardDescription}>
                  {level.descriptionCondition}
                </p>
              </div>
            ))
          ) : (
            <p>No condition levels available</p>
          )}
        </div>
        <div className={styles.rightColumn}>
          <img
            src={
              verticalImage ? urlFor(verticalImage).url() : "fallback-image-url"
            } // Ensure verticalImage is passed correctly
            alt="Vertical"
            className={styles.verticalImage}
          />
          <p>{verticalImageDescription}</p>
        </div>
      </div>
    </div>
  );
};

export default ConditionLevel;
