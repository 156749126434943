// import React from "react";
// import styles from "./WhySuntec.module.css";
// import img from "./fi_686348.png";

// const WhySuntec = () => {
//   const cards = Array(8).fill({
//     icon: img,
//     title: "Superior Quality Metallic Abrasives",
//     description:
//       "Embracing the latest technological advancements, we continuously innovate to improve our products and services. We help you receive cutting-edge solutions.",
//   });

//   return (
//     <div className={styles.wrapper}>
//       <div className={styles.container}>
//         <p className={styles.title}>Why Suntec</p>
//         <p className={styles.description}>
//           Discover unmatched excellence in quality and precision, trusted by
//           industries worldwide
//         </p>
//         <div className={styles.cardsContainer}>
//           {cards.map((card, index) => (
//             <div key={index} className={styles.card}>
//               <img src={card.icon} alt="Icon" className={styles.icon} />
//               <h2 className={styles.cardTitle}>{card.title}</h2>
//               <p className={styles.cardDescription}>{card.description}</p>
//             </div>
//           ))}
//         </div>
//       </div>
//     </div>
//   );
// };

// export default WhySuntec;

import React, { useEffect, useState } from "react";
import styles from "./WhySuntec.module.css";
import client from "../../sanityClient"; // Adjust the import path as needed
import imageUrlBuilder from "@sanity/image-url";

// Set up the builder for generating image URLs
const builder = imageUrlBuilder(client);

function urlFor(source) {
  return builder.image(source);
}

const WhySuntec = () => {
  const [content, setContent] = useState(null);

  useEffect(() => {
    client
      .fetch(
        `*[_type == "whySuntec"]{
          title,
          description,
          cards[] {
            icon,
            title,
            description
          }
        }[0]`
      )
      .then((data) => setContent(data))
      .catch(console.error);
  }, []);

  if (!content) {
    return <div></div>;
  }

  return (
    <div className={styles.wrapper}>
      <div className={styles.container}>
        <p className={styles.title}>{content.title}</p>
        <p className={styles.description}>{content.description}</p>
        <div className={styles.cardsContainer}>
          {content.cards.map((card, index) => (
            <div key={index} className={styles.card}>
              <img
                src={urlFor(card.icon).url()}
                alt="Icon"
                className={styles.icon}
              />
              <h2 className={styles.cardTitle}>{card.title}</h2>
              <p className={styles.cardDescription}>{card.description}</p>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default WhySuntec;
