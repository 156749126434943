// import React from "react";
// import styles from "./IntroAbout.module.css";
// import aboutImage from "./image 20 (2).png"; // Existing image
// import bottomImage from "./image.png";

// const IntroAbout = () => {
//   return (
//     <div className={styles.container}>
//       <div className={styles.leftCol}>
//         <p className={styles.qualities}>Quality | Precision | Reliability</p>
//         <h2 className={styles.heading}>
//           About Our Company
//           <br />
//           Suntec
//         </h2>
//         <p className={styles.description}>
//           You are the expert on your products and we are your reliable partner
//           to make your product perfect. At Suntec Enterprises, we have been
//           providing reliable solutions for shot peening and shot blasting
//           requirements since 2002.
//         </p>
//       </div>
//       <div className={styles.rightCol}>
//         <img src={aboutImage} alt="About Suntec" className={styles.image} />
//       </div>
//       <div className={styles.fullWidthImageWrapper}>
//         <img src={bottomImage} alt="Bottom" className={styles.fullWidthImage} />
//       </div>
//     </div>
//   );
// };

// export default IntroAbout;

import React, { useState, useEffect } from "react";
import client from "../../sanityClient"; // Adjust the path according to your setup
import styles from "./IntroAbout.module.css";

const IntroAbout = () => {
  const [content, setContent] = useState(null);

  useEffect(() => {
    client
      .fetch(
        `*[_type == "introAbout"][0]{
          qualities,
          heading,
          description,
          "aboutImageUrl": aboutImage.asset->url,
          "bottomImageUrl": bottomImage.asset->url
        }`
      )
      .then((data) => setContent(data))
      .catch(console.error);
  }, []);

  if (!content) return <div></div>;

  return (
    <div className={styles.container}>
      <div className={styles.leftCol}>
        <p className={styles.qualities}>{content.qualities.join(" | ")}</p>
        <h2 className={styles.heading}>
          {content.heading}
          {/* <br /> */}
          {/* {content.heading.split(" ")[1]} */}
        </h2>
        <p className={styles.description}>{content.description}</p>
      </div>
      <div className={styles.rightCol}>
        <img
          src={content.aboutImageUrl}
          alt="About Suntec"
          className={styles.image}
        />
      </div>
      <div className={styles.fullWidthImageWrapper}>
        <img
          src={content.bottomImageUrl}
          alt="Bottom"
          className={styles.fullWidthImage}
        />
      </div>
    </div>
  );
};

export default IntroAbout;
