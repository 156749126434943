// import React from "react";
// import styles from "./IndustryServedList.module.css";
// import img from "../image 20 (2).png";
// import icon from "../icon.png";

// const IndustryServedList = () => {
//   const industries = [
//     {
//       id: 1,
//       title: "Automotive Industry",
//       description: `Cut wire shots are extensively used in the automotive industry for shot peening and cleaning purposes.
//       They help in enhancing the fatigue strength of automotive components such as gears, springs, and connecting rods.
//       The process involves bombarding the surface of these parts with high-velocity cut wire shots, which induces beneficial compressive residual stresses.
//       This results in improved durability and performance of the components. Additionally, cut wire shots are employed in the cleaning of engine blocks, cylinder heads, and other parts to remove casting sand, scale, and rust.`,
//       imgSrc: img,
//     },
//     {
//       id: 2,
//       title: "Aerospace Industry",
//       description: `In the aerospace industry, cut wire shots play a critical role in shot peening processes to enhance the fatigue life of aircraft components.
//       This process is crucial for parts subjected to high stress, such as turbine blades, landing gear, and structural components.
//       By using cut wire shots, the surface of these components is hardened, thereby increasing their resistance to crack initiation and propagation.
//       The precise and controlled application of cut wire shots ensures the reliability and safety of aerospace components, meeting stringent industry standards.`,
//       imgSrc: img,
//     },
//     {
//       id: 3,
//       title: "Construction Industry",
//       description: `In the construction industry, cut wire shots are used for surface preparation, cleaning, and peening of structural steel and other components.
//       The process helps in removing scale, rust, and other contaminants, providing a clean surface for further treatment such as coating or painting.
//       Shot peening using cut wire shots enhances the fatigue strength of steel components, making them more durable and resilient under load-bearing conditions.`,
//       imgSrc: img,
//     },
//     {
//       id: 4,
//       title: "Construction Industry",
//       description: `In the construction industry, cut wire shots are used for surface preparation, cleaning, and peening of structural steel and other components.
//       The process helps in removing scale, rust, and other contaminants, providing a clean surface for further treatment such as coating or painting.
//       Shot peening using cut wire shots enhances the fatigue strength of steel components, making them more durable and resilient under load-bearing conditions.`,
//       imgSrc: img,
//     },
//   ];

//   const renderCards = () => (
//     <div className={styles.cardContainer}>
//       <div className={styles.card}>
//         <div className={styles.cardIcon}>
//           <img src={icon} />
//         </div>
//         <h3>Enhanced Durability</h3>
//         <p>
//           Embracing the latest technological advancements, we continuously
//           innovate to improve our products and services.
//         </p>
//       </div>
//       <div className={styles.card}>
//         <div className={styles.cardIcon}>
//           <img src={icon} />
//         </div>
//         <h3>Cutting-Edge Technology</h3>
//         <p>
//           We help you receive cutting-edge solutions that meet industry
//           standards and ensure high performance.
//         </p>
//       </div>
//       <div className={styles.card}>
//         <div className={styles.cardIcon}>
//           <img src={icon} />
//         </div>
//         <h3>Precision Engineering</h3>
//         <p>
//           Our solutions are designed with precision to enhance the reliability
//           and safety of your components.
//         </p>
//       </div>
//     </div>
//   );

//   return (
//     <div className={styles.industryList}>
//       {industries.map((industry) => (
//         <div key={industry.id} className={styles.industry}>
//           <div className={`${styles.industryContent} `}>
//             <div className={styles.textContent}>
//               <h2 className={styles.title}>{industry.title}</h2>
//               <hr className={styles.hrLine} />
//               <p className={styles.description}>{industry.description}</p>
//             </div>
//             <div className={styles.industryImage}>
//               <img src={industry.imgSrc} alt={industry.title} />
//             </div>
//           </div>
//           {renderCards()}
//         </div>
//       ))}
//     </div>
//   );
// };

// export default IndustryServedList;

import React, { useEffect, useState } from "react";
import styles from "./IndustryServedList.module.css";
import client from "../../sanityClient"; // Adjust the path to your sanity client

const IndustryServedList = () => {
  const [industries, setIndustries] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const query = `*[_type == "industryWithCards"]{
          _id,
          title,
          description,
          imgSrc {
            asset->{
              url
            }
          },
          cards[]{
            title,
            description,
            icon {
              asset->{
                url
              }
            }
          }
        }`;

        const data = await client.fetch(query);
        setIndustries(data);
      } catch (error) {
        console.error("Error fetching data from Sanity:", error);
      }
    };

    fetchData();
  }, []);

  const renderCards = (cards) => (
    <div className={styles.cardContainer}>
      {cards.map((card, index) => {
        const cardIconUrl = card.icon?.asset?.url; // Ensure the icon URL is available
        return (
          <div key={index} className={styles.card}>
            <div className={styles.cardIcon}>
              {cardIconUrl ? (
                <img src={cardIconUrl} alt={card.title} />
              ) : (
                <p>No Icon</p> // Fallback if icon URL is not available
              )}
            </div>
            <h3>{card.title}</h3>
            <p>{card.description}</p>
          </div>
        );
      })}
    </div>
  );

  return (
    <div className={styles.industryList}>
      {industries.map((industry) => (
        <div key={industry._id} className={styles.industry}>
          <div className={styles.industryContent}>
            <div className={styles.textContent}>
              <h2 className={styles.title}>{industry.title}</h2>
              <hr className={styles.hrLine} />
              <p className={styles.description}>{industry.description}</p>
            </div>
            <div className={styles.industryImage}>
              {industry.imgSrc?.asset?.url ? (
                <img src={industry.imgSrc.asset.url} alt={industry.title} />
              ) : (
                <p>No Image</p> // Fallback if image URL is not available
              )}
            </div>
          </div>
          {renderCards(industry.cards)}
        </div>
      ))}
    </div>
  );
};

export default IndustryServedList;
