// // WireShot.js
// import React from "react";
// import { Link } from "react-router-dom";
// import styles from "./WireShot.module.css"; // Import CSS Module
// import img1 from "./image 20.png";
// import img2 from "./image 29.png";

// const WireShot = () => {
//   return (
//     <div className={styles.container}>
//       <div className={styles.header}>
//         <h1 className={styles.title}>What are Cut Wire Shots?</h1>
//         <div className={styles.secondDiv}>
//           <p className={styles.description}>
//             Cut wire shots are small metal pieces used for various industrial
//             purposes, like cleaning, peening, or shot blasting. They're made by
//             cutting steel wire into precise lengths.
//           </p>
//           <Link to="/wireshot" className={styles.button}>
//             Learn More
//           </Link>
//         </div>
//       </div>

//       <div className={styles.content}>
//         <div className={styles.leftColumn}>
//           <div className={styles.card}>
//             <img src={img2} alt="CCW G1" className={styles.cardImage} />
//             <h2 className={styles.cardTitle}>CCW – Single Conditioned (G1)</h2>
//             <p className={styles.cardDescription}>
//               These cut wire shots have sharp edges and corners, like tiny
//               pieces of metal with pointed ends.
//             </p>
//           </div>
//           <div className={styles.card}>
//             <img src={img2} alt="CCW G2" className={styles.cardImage} />
//             <h2 className={styles.cardTitle}>CCW – Single Conditioned (G2)</h2>
//             <p className={styles.cardDescription}>
//               These cut wire shots are double-conditioned, offering a smoother
//               surface.
//             </p>
//           </div>
//           <div className={styles.card}>
//             <img src={img2} alt="CCW G3" className={styles.cardImage} />
//             <h2 className={styles.cardTitle}>CCW – Single Conditioned (G3)</h2>
//             <p className={styles.cardDescription}>
//               These shots are thoroughly conditioned for even better surface
//               finish.
//             </p>
//           </div>
//           <div className={styles.card}>
//             <img src={img2} alt="CCW G4" className={styles.cardImage} />
//             <h2 className={styles.cardTitle}>CCW – Single Conditioned (G4)</h2>
//             <p className={styles.cardDescription}>
//               These shots offer excellent durability and a consistent
//               performance.
//             </p>
//           </div>
//         </div>
//         <div className={styles.rightColumn}>
//           <img src={img1} alt="Vertical" className={styles.verticalImage} />
//         </div>
//       </div>
//     </div>
//   );
// };

// export default WireShot;

import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import styles from "./WireShot.module.css";
import client from "../../sanityClient"; // Adjust path as necessary
import imageUrlBuilder from "@sanity/image-url";

const builder = imageUrlBuilder(client);

const urlFor = (source) => builder.image(source);

const WireShot = () => {
  const [data, setData] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const query = '*[_type == "wireShot"][0]';
        const result = await client.fetch(query);
        setData(result);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  if (!data) return <p></p>;

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <h1 className={styles.title}>{data.title}</h1>
        <div className={styles.secondDiv}>
          <p className={styles.description}>{data.description}</p>
          <div className={styles.btnDiv}>
            <Link to={data.buttonLink} className={styles.button}>
              {data.buttonText}
            </Link>
          </div>
        </div>
      </div>

      <div className={styles.content}>
        <div className={styles.leftColumn}>
          {data.cards.map((card, index) => (
            <div key={index} className={styles.card}>
              <img
                src={urlFor(card.image).url()}
                alt={card.title}
                className={styles.cardImage}
              />
              <h2 className={styles.cardTitle}>{card.title}</h2>
              <p className={styles.cardDescription}>{card.description}</p>
            </div>
          ))}
        </div>
        <div className={styles.rightColumn}>
          <img
            src={urlFor(data.verticalImage).url()}
            alt="Vertical"
            className={styles.verticalImage}
          />
        </div>
      </div>
    </div>
  );
};

export default WireShot;
