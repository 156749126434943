import React from "react";
import ContactForm from "./ContactForm";
import ContactBanner from "./contactBanner/ContactBanner";

const Contact = () => {
  return (
    <div>
      <ContactBanner />
      <ContactForm />
    </div>
  );
};

export default Contact;
