import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Slider from "react-slick";
import styles from "./Blogs.module.css";
import client from "../../sanityClient";
import rightArrow from "./rightArrow.png";

const Blogs = () => {
  const [blogs, setBlogs] = useState([]);
  const [headerText, setHeaderText] = useState("");
  const [descriptionText, setDescriptionText] = useState("");
  const [seeMoreButtonText, setSeeMoreButtonText] = useState("");
  const [error, setError] = useState(null);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
  const navigate = useNavigate();

  useEffect(() => {
    const handleResize = () => setIsMobile(window.innerWidth <= 768);

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    const fetchContent = async () => {
      try {
        const content = await client.fetch(`*[_type == "websiteContent"][0]{
          headerText,
          descriptionText,
          seeMoreButtonText
        }`);
        setHeaderText(content.headerText);
        setDescriptionText(content.descriptionText);
        setSeeMoreButtonText(content.seeMoreButtonText);
      } catch (error) {
        console.error("Error fetching content: ", error);
        setError("Failed to load content");
      }
    };

    const fetchBlogs = async () => {
      try {
        const data = await client.fetch(`*[_type == "blog"]{
          _id,
          category,
          title,
          image {
            asset -> {
              url
            }
          },
          shortText,
          datePosted,
          readingTime,
          content
        }`);
        setBlogs(data);
      } catch (error) {
        console.error("Error fetching blogs: ", error);
        setError("Failed to load blogs");
      }
    };

    fetchContent();
    fetchBlogs();
  }, []);

  const handleReadMore = (blog) => {
    navigate(`/blog/${blog._id}`, { state: { blog } });
  };

  const settings = {
    dots: true,
    infinite: true,
    arrows: false, // Disable default arrows
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 5000,
  };

  return (
    <div className={styles.blogContainer}>
      {error && <p className={styles.errorText}>{error}</p>}
      <div className={styles.blogWrapper}>
        <div className={styles.headerRow}>
          <div className={styles.headerCol}>
            <h1 className={styles.headerText}>{headerText}</h1>
          </div>
          <div className={styles.descCol}>
            <p className={styles.paraText}>{descriptionText}</p>
            <Link to="/blog" className={styles.seeMoreButton}>
              {seeMoreButtonText}
            </Link>
          </div>
        </div>

        {isMobile ? (
          <div className={styles.sliderContainer}>
            <Slider {...settings}>
              {blogs.map((blog) => (
                <div
                  key={blog._id}
                  className={styles.card}
                  onClick={() => handleReadMore(blog)}
                >
                  <div className={styles.cardHeader}>
                    <div className={styles.circleDot}></div>
                    <span className={styles.time}>{blog.readingTime}</span>
                  </div>
                  <h2 className={styles.blogHeading}>{blog.title}</h2>
                  <img
                    className={styles.blogImage}
                    src={blog.image?.asset?.url || "placeholder-image-url"}
                    alt={blog.title}
                  />
                  <div className={styles.cardFooter}>
                    <p className={styles.shortText}>{blog.shortText}</p>
                    <button
                      className={styles.readMoreButton}
                      onClick={() => handleReadMore(blog)}
                    >
                      <img
                        className={styles.arrowImg}
                        src={rightArrow}
                        alt="right arrow"
                      />
                    </button>
                  </div>
                </div>
              ))}
            </Slider>
          </div>
        ) : (
          <div className={styles.cardsRow}>
            {blogs.map((blog) => (
              <div
                key={blog._id}
                className={styles.card}
                onClick={() => handleReadMore(blog)}
              >
                <div className={styles.cardHeader}>
                  <div className={styles.circleDot}></div>
                  <span className={styles.time}>{blog.readingTime}</span>
                </div>
                <h2 className={styles.blogHeading}>{blog.title}</h2>
                <img
                  className={styles.blogImage}
                  src={blog.image?.asset?.url || "placeholder-image-url"}
                  alt={blog.title}
                />
                <div className={styles.cardFooter}>
                  <p className={styles.shortText}>{blog.shortText}</p>
                  <button
                    className={styles.readMoreButton}
                    onClick={() => handleReadMore(blog)}
                  >
                    <img
                      className={styles.arrowImg}
                      src={rightArrow}
                      alt="right arrow"
                    />
                  </button>
                </div>
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

export default Blogs;
