import React from "react";
import WireShotsHero from "./WireShotsHero/WireShotsHero";
import WireShotDescription from "./WireShotDescription/WireShotDescription";
import WireShotCards from "./WireShotCards/WireShotCards";
import WireShotHistory from "./WireShotHistory/WireShotHistory";
import WireShotApplication from "./WireShotApplication/WireShotApplication";

const WireShotDetail = () => {
  return (
    <div>
      <WireShotsHero />
      <WireShotDescription />
      <WireShotCards />
      <WireShotHistory />
      <WireShotApplication />
    </div>
  );
};

export default WireShotDetail;
