import React, { useState, useEffect } from "react";
import styles from "./FAQ.module.css";
import dropIcon from "./fi_2985150 (2).png";
import client from "../../sanityClient"; // Adjust the path to your sanityClient

const Faq = () => {
  const [faqs, setFaqs] = useState([]);
  const [activeIndex, setActiveIndex] = useState(null);

  useEffect(() => {
    const fetchFAQs = async () => {
      try {
        const query = '*[_type == "faq"]{question, answer, icon{asset->{url}}}';
        const data = await client.fetch(query);
        setFaqs(data);
      } catch (error) {
        console.error("Error fetching FAQs:", error);
      }
    };

    fetchFAQs();
  }, []);

  const toggleFAQ = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  return (
    <div className={styles.wrapper}>
      <h2 className={styles.title}>Frequently Asked Questions</h2>

      <div className={styles.faqContainer}>
        <div className={styles.column}>
          {faqs.map((faq, index) => (
            <div key={index} className={styles.faqItem}>
              <div
                className={styles.faqHeader}
                onClick={() => toggleFAQ(index)}
              >
                <span className={styles.faqQuestion}>{faq.question}</span>
                <span className={styles.dropdown}>
                  <img src={dropIcon} alt="dropIcon" />
                  {/* {activeIndex === index ? "-" : "+"} */}
                </span>
              </div>
              {activeIndex === index && (
                <div className={styles.faqAnswer}>{faq.answer}</div>
              )}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Faq;
