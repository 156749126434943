import React from "react";
import styles from "./Performance.module.css";

const Card = ({ icon, title, description }) => {
  return (
    <div className={styles.card}>
      <div className={styles.icon}>{icon}</div>
      <h3 className={styles.cardTitle}>{title}</h3>
      <p className={styles.cardDescription}>{description}</p>
    </div>
  );
};

const Performance = ({ performance }) => {
  if (!performance || performance.length === 0) {
    return <div>No performance data available.</div>; // Handle case where performance data is not available
  }

  return (
    <div className={styles.performanceContainer}>
      <h2 className={styles.heading}>Performance</h2>
      <div className={styles.cardGrid}>
        {performance.map((item) => (
          <Card
            key={item.id}
            icon={item.icon}
            title={item.title}
            description={item.description}
          />
        ))}
      </div>
      <div>
        <button className={styles.downloadButton}>Download Brochure</button>
      </div>
    </div>
  );
};

export default Performance;
