import React from "react";
import IntroAbout from "./IntroAbout/IntroAbout";
import AboutStat from "./AboutStat/AboutStat";
import AboutCommitment from "./AboutCommitment/AboutCommitment";
import WhySuntec from "./WhySuntec/WhySuntec";

const AboutOne = () => {
  return (
    <div>
      <IntroAbout />
      <AboutStat />
      <AboutCommitment />
      <WhySuntec />
    </div>
  );
};

export default AboutOne;
