import React, { useEffect, useState } from "react";
import client from "../../sanityClient";
import styles from "./Banner.module.css";
import img from "./Ellipse 7 (1).png";
import img2 from "./Ellipse 8 (1).png";
import { Link } from "react-router-dom";

const Banner = () => {
  const [bannerData, setBannerData] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      const query = `*[_type == "banner"][0]`;
      const data = await client.fetch(query);
      setBannerData(data);
    };

    fetchData();
  }, []);

  if (!bannerData) {
    return <div></div>;
  }

  return (
    <div className={styles.banner}>
      <div className={styles.bannerDiv}>
        <h1 className={styles.heading}>{bannerData.heading}</h1>
        <p className={styles.description}>{bannerData.description}</p>
        <div className={styles.btnDiv}>
          <Link to="/contact-us" className={styles.quoteButton}>
            {bannerData.buttonText}
          </Link>
        </div>
      </div>
      <div className={styles.imgoneDiv}>
        <img className={styles.img} src={img} alt="bgImg" />
      </div>
      <div className={styles.imgtwoDiv}>
        <img src={img2} className={styles.img2} alt="bgImg" />
      </div>
    </div>
  );
};

export default Banner;
