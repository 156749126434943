import React, { useEffect, useState } from "react";
import client from "../../sanityClient"; // Import your Sanity client
import styles from "./QualityMeasures.module.css"; // Importing CSS module
import { Link } from "react-router-dom";
import imageUrlBuilder from "@sanity/image-url";

const builder = imageUrlBuilder(client);

function urlFor(source) {
  return builder.image(source);
}

const QualityMeasures = () => {
  const [data, setData] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      const query = '*[_type == "qualityMeasures"][0]'; // Fetch the first document of type 'qualityMeasures'
      const result = await client.fetch(query);
      setData(result);
    };

    fetchData();
  }, []);

  if (!data) {
    return <div></div>; // Added loading state
  }

  return (
    <div className={styles.container}>
      <div className={styles.leftWrapper}>
        <div className={styles.leftCol}>
          <h2 className={styles.header}>{data.header}</h2>
          <p className={styles.subheader}>{data.subheader}</p>
          {/* <button className={styles.button}>{data.buttonText}</button> */}
        </div>
        <div>
          <Link to="/contact-us" className={styles.button}>
            {data.buttonText}
          </Link>
        </div>
      </div>

      <div className={styles.rightCol}>
        {data.cards.map((card, index) => (
          <div key={index} className={styles.card}>
            <div className={styles.icon}>
              {card.icon && (
                <img src={urlFor(card.icon).url()} alt={card.title} />
              )}
            </div>
            <h3 className={styles.title}>{card.title}</h3>
            <p className={styles.description}>{card.description}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default QualityMeasures;
