import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import styles from "./HeroSection.module.css";
import client from "../../sanityClient";
import img from "./Ellipse 6.png";
import img2 from "./Ellipse 7.png";
import img3 from "./Ellipse 8.png";

const HeroSection = () => {
  const [heroContent, setHeroContent] = useState(null);

  useEffect(() => {
    const fetchHeroContent = async () => {
      const query =
        '*[_type == "heroSection"]{heading, description, contactButtonText, learnButtonText,  heroImg{asset->{url}}}[0]';
      try {
        const data = await client.fetch(query);
        setHeroContent(data);
      } catch (error) {
        console.error("Failed to fetch hero content", error);
      }
    };

    fetchHeroContent();
  }, []);

  if (!heroContent) return <div></div>;

  return (
    <div className={styles.aboutUsContainer}>
      <div className={styles.leftContent}>
        <h2 className={styles.heading}>{heroContent.heading}</h2>
        <p className={styles.description}>{heroContent.description}</p>

        <div className={styles.buttons}>
          <Link
            to="/contact-us"
            className={`${styles.btnPrimary} ${styles.button}`}
          >
            {heroContent.contactButtonText}
          </Link>

          <Link
            to="/about/company"
            className={`${styles.btnSecondary} ${styles.button}`}
          >
            {heroContent.learnButtonText}
          </Link>
        </div>
      </div>
      <div className={styles.rightContent}>
        <img
          src={heroContent.heroImg.asset.url}
          alt="Hero Section"
          className={styles.image}
        />
      </div>
      <div className={styles.imgEclipse}>
        <img src={img} alt="Eclipse decoration" />
      </div>
      <div className={styles.imgEclipseTwo}>
        <img src={img2} alt="Eclipse decoration" />
      </div>
      <div className={styles.imgEclipseThree}>
        <img src={img3} alt="Eclipse decoration" />
      </div>
    </div>
  );
};

export default HeroSection;
