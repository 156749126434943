import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import styles from "./Product.module.css"; // Adjust the path as needed
import client, { urlFor } from "../../sanityClient"; // Adjust the path as needed
const Products = () => {
  const [products, setProducts] = useState([]);
  const [pageTitle, setPageTitle] = useState("");
  const [pageSubtitle, setPageSubtitle] = useState("");
  const [pageDescription, setPageDescription] = useState("");
  const [verticalImage, setVerticalImage] = useState({});
  const [verticalImageDescription, setVerticalImageDescription] = useState("");
  const navigate = useNavigate();

  useEffect(() => {
    client
      .fetch(
        `*[_type == "productPageContent"]{
          title, 
          subtitle, 
          description, 
          products[] {
            metalName,
            title,
            description,
            imgSrc {
              asset -> {
                _id
              }
            },
            id,
            productbannerDescription,
            additionalInfo {
              why,
              longLife,
              recyclable,
              nonCombustible,
              platingFiller,
              equipment,
              shape,
              industrySpecifications,
              bulkDensity,
              alloy,
              packaging
            },
            conditionLevels[] {
              title,
              description,
              image {
                asset -> {
                  _id
                }
              },
              altText
            },
            performance[] {
              icon,
              title,
              description
            },
            applications[] {
              title,
              icon {
                asset -> {
                  _id
                }
              }
            }
          }
        }`
      )
      .then((data) => {
        if (data.length > 0) {
          const pageContent = data[0];
          setPageTitle(pageContent.title || "");
          setPageSubtitle(pageContent.subtitle || "");
          setPageDescription(pageContent.description || "");
          setVerticalImage(pageContent.verticalImage || {});
          setVerticalImageDescription(
            pageContent.verticalImageDescription || ""
          );
          setProducts(pageContent.products || []);
        }
      })
      .catch((error) => {
        console.error("Error fetching product data:", error);
      });
  }, []);

  const handleProductClick = (product) => {
    navigate(`/product-detail/${product.metalName}`, {
      state: { productData: product },
    });
  };

  return (
    <div className={styles.wrapper}>
      <div className={styles.container}>
        <p className={styles.subtitle}>{pageSubtitle}</p>
        <h1 className={styles.title}>{pageTitle}</h1>
        <h1 className={styles.description}>{pageDescription}</h1>
        <div className={styles.cardsContainer}>
          {products.map((product, index) => (
            <ProductCard
              key={product.metalName}
              title={product.title}
              description={product.description}
              imgSrc={
                product.imgSrc?.asset ? urlFor(product.imgSrc.asset).url() : ""
              }
              onClick={() => handleProductClick(product)}
              layoutType={index % 2 === 0 ? "leftText" : "leftImage"} // Alternate layout
            />
          ))}
        </div>
      </div>
    </div>
  );
};

const ProductCard = ({ title, description, imgSrc, onClick, layoutType }) => {
  return (
    <div
      className={`${styles.card} ${
        layoutType === "leftText" ? styles.leftText : styles.leftImage
      }`}
      onClick={onClick}
    >
      <img src={imgSrc} alt={title} className={styles.image} />
      <div className={styles.textContainer}>
        <h3 className={styles.productTitle}>{title}</h3>
        <p className={styles.productDescription}>{description}</p>
        <a className={styles.readMore} onClick={onClick}>
          Read more
        </a>
      </div>
    </div>
  );
};
export default Products;
