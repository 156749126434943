import React, { useEffect, useState } from "react";
import styles from "./PrivacyPolicy.module.css";
import client from "../sanityClient";

const PrivacyPolicy = () => {
  const [policy, setPolicy] = useState(null);

  useEffect(() => {
    client
      .fetch(
        `*[_type == "privacyPolicy"]{
          topText,
          heading,
          text,
          effectiveDate,
          subheading,
          sections[] {
            sectionHeading,
            sectionText
          }
        }`
      )
      .then((data) => {
        setPolicy(data[0]);
      })
      .catch(console.error);
  }, []);

  if (!policy) {
    return <div></div>;
  }

  return (
    <div className={styles.privacyPolicy}>
      <h1 className={styles.topText}>{policy.topText}</h1>
      <h2 className={styles.heading}>{policy.heading}</h2>
      <div className={styles.text}>
        {policy.text.map((block, index) => (
          <p className={styles.para} key={index}>
            {block.children[0].text}
          </p>
        ))}
      </div>
      <div className={styles.date}>
        <p className={styles.effectiveDate}>{policy.effectiveDate}</p>
        {/* <p className={styles.subheading}>{policy.subheading}</p> */}
      </div>

      {policy.sections.map((section, index) => (
        <div className={styles.section} key={index}>
          <h3 className={styles.sectionHeading}>{section.sectionHeading}</h3>
          {section.sectionText.map((block, idx) => (
            <p className={styles.text} key={idx}>
              {block.children[0].text}
            </p>
          ))}
        </div>
      ))}
    </div>
  );
};

export default PrivacyPolicy;
