import React, { useEffect, useState } from "react";
import client from "../../sanityClient"; // Adjust the import path based on your project structure
import styles from "./Customer.module.css";

const Customer = () => {
  const [data, setData] = useState(null);

  useEffect(() => {
    client
      .fetch(
        `*[_type == "customerSection"][0]{
          title,
          customers[]{
            name,
            "imageUrl": image.asset->url
          }
        }`
      )
      .then((data) => setData(data))
      .catch(console.error);
  }, []);

  if (!data) {
    return <div></div>;
  }

  return (
    <section className={styles.customerSection}>
      <h2 className={styles.title}>{data.title}</h2>
      <div className={styles.marqueeContainer}>
        <div className={styles.marquee}>
          {data.customers.map((customer, index) => (
            <img
              key={index}
              src={customer.imageUrl}
              alt={customer.name}
              className={styles.icon}
            />
          ))}
          {data.customers.map((customer, index) => (
            <img
              key={`${index}-duplicate`}
              src={customer.imageUrl}
              alt={customer.name}
              className={styles.icon}
            />
          ))}
        </div>
      </div>
    </section>
  );
};

export default Customer;
