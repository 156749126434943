import React, { useEffect, useState } from "react";
import client, { urlFor } from "../../sanityClient";
import styles from "./AboutUs.module.css";

const AboutUs = () => {
  const [data, setData] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      const query = `*[_type == "aboutUs"][0]`;
      const result = await client.fetch(query);
      setData(result);
    };

    fetchData();
  }, []);

  if (!data) {
    return <div></div>;
  }

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <h1 className={styles.title}>{data.title}</h1>
        <p className={styles.description}>{data.description}</p>
      </div>
      <div className={styles.cardsContainer}>
        {data.cards.map((card, index) => (
          <div key={index} className={`${styles.card} `}>
            <div className={styles.icon}>
              <img src={urlFor(card.icon).toString()} alt={card.title} />
            </div>
            <p className={styles.cardTitle}>{card.title}</p>
            <p className={styles.cardDescription}>{card.description}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default AboutUs;
