import { createClient } from "@sanity/client";
import imageUrlBuilder from "@sanity/image-url";

// Initialize Sanity client
const client = createClient({
  projectId: "o46e8fg2",
  dataset: "production",
  token: process.env.SANITY_API_TOKEN,
  // apiVersion: "2023-09-01",
  useCdn: true,
});

const builder = imageUrlBuilder(client);

export const urlFor = (source) => builder.image(source);

export default client;
