// import React from "react";
// import styles from "./Specification.module.css";

// const specifications = [
//   "VDFI 8001",
//   "DIN 8201-4",
//   "ISO 9001",
//   "ANSI Z87.1",
//   "ASTM D6400",
//   "EN 166",
//   "GB/T 19001",
// ];

// const Specification = () => {
//   return (
//     <div className={styles.container}>
//       {specifications.map((spec, index) => (
//         <div
//           key={index}
//           className={`${styles.card} ${
//             index === 6 ? styles.fullWidthCard : ""
//           }`}
//         >
//           <p className={styles.specification}>{spec}</p>
//         </div>
//       ))}
//     </div>
//   );
// };

// export default Specification;

import React, { useEffect, useState } from "react";
import client from "../../sanityClient"; // Adjust the path as needed
import styles from "./Specification.module.css";

const Specification = () => {
  const [specifications, setSpecifications] = useState([]);

  useEffect(() => {
    const fetchSpecifications = async () => {
      try {
        const query = `*[_type == "specification"] | order(_createdAt desc) {
          _id,
          title
        }`;
        const data = await client.fetch(query);
        setSpecifications(data);
      } catch (error) {
        console.error("Failed to fetch specifications:", error);
      }
    };

    fetchSpecifications();
  }, []);

  return (
    <div className={styles.containerWrapper}>
      <h1 className={styles.header}>Our Cut- Wire Specifications</h1>
      <div className={styles.container}>
        {specifications.map((spec, index) => (
          <div
            key={spec._id}
            className={`${styles.card} ${
              index === specifications.length - 1 ? styles.fullWidthCard : ""
            }`}
          >
            <p className={styles.specification}>{spec.title}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Specification;
